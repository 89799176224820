var render = function () {
  var _vm$noticeDetails, _vm$noticeDetails2, _vm$noticeRecieverOpt, _vm$noticeDetails3, _vm$noticeRecieverOpt2, _vm$noticeDetails4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-card-text', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4",
      "cols": "12"
    }
  }, [(_vm$noticeDetails = _vm.noticeDetails) !== null && _vm$noticeDetails !== void 0 && _vm$noticeDetails.title ? [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Notice Title")]), _c('div', [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$noticeDetails2 = _vm.noticeDetails) === null || _vm$noticeDetails2 === void 0 ? void 0 : _vm$noticeDetails2.title))])], 1)] : _vm._e(), (_vm$noticeRecieverOpt = _vm.noticeRecieverOptoins[((_vm$noticeDetails3 = _vm.noticeDetails) === null || _vm$noticeDetails3 === void 0 ? void 0 : _vm$noticeDetails3.type) - 1]) !== null && _vm$noticeRecieverOpt !== void 0 && _vm$noticeRecieverOpt.name ? [_c('h5', {
    staticClass: "text-capitalize mt-2"
  }, [_vm._v("Notice Type")]), _c('div', [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$noticeRecieverOpt2 = _vm.noticeRecieverOptoins[((_vm$noticeDetails4 = _vm.noticeDetails) === null || _vm$noticeDetails4 === void 0 ? void 0 : _vm$noticeDetails4.type) - 1]) === null || _vm$noticeRecieverOpt2 === void 0 ? void 0 : _vm$noticeRecieverOpt2.name))])], 1)] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "lg": "6",
      "cols": "12"
    }
  }, [_vm.noticeDetails.description ? [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Notice Description")]), _c('div', {
    staticClass: "ql-editor"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.noticeDetails.description)
    }
  })])] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-card-body', [[_c('b-table', {
    attrs: {
      "responsive": "sm",
      "items": _vm.noticeRecipients,
      "fields": _vm.tableFields
    }
  })]], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }